<template>
  <div>
    <div
      class="innerContent"
      style="
        height: calc(100vh);
        width: 100%;
        min-width: 85%;
        max-width: 100%;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <v-row class="pa-2" no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card class="infoCards ma-1" flat>
            <v-card-text class="ma-0 pa-0">
              <v-list dense class="my-0 py-0">
                <v-subheader style="font-size: 14px">
                  <v-icon class="mr-2" small>handshake</v-icon>
                  Parties <v-spacer></v-spacer>
                  <v-chip
                    v-if="shipment.consigneeProfile"
                    small
                    @click="viewProfile()"
                  >
                    Profile: {{ shipment.consigneeProfile.systemReference }}
                    <v-icon small color="grey" right v-if="!loadingProfile"
                      >launch</v-icon
                    >
                    <v-progress-circular
                      right
                      color="primary"
                      v-else
                      size="16"
                      :width="3"
                      indeterminate
                    ></v-progress-circular>
                  </v-chip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="party of listedParties" :key="party.id">
                  <v-list-item-action class="mr-1">
                    <v-avatar
                      size="30"
                      :color="party.logo ? 'white' : 'secondary'"
                    >
                      <v-img
                        v-if="party.logo"
                        contain
                        :src="party.logo"
                      ></v-img>
                      <h3 v-else>
                        {{ party.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">
                      {{ party.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      class="text-wrap"
                      v-if="party.linkedValue && shipment[party.linkedValue]"
                    >
                      On behalf of {{ shipment[party.linkedValue].name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      v-if="shipment[party.roleValue + 'Address']"
                      class="text-wrap"
                    >
                      <v-menu transition="scale-transition" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            small
                            label
                            outlined
                            style="border: none; font-size: 11px"
                            @click="
                              getPartyAddresses(
                                shipment[party.roleValue],
                                shipment[party.roleValue + 'Id']
                              )
                            "
                            v-on="on"
                          >
                            <v-icon left x-small>location_on</v-icon>
                            {{
                              concatenateAddress(
                                shipment[party.roleValue + "Address"]
                              )
                            }}
                          </v-chip>
                        </template>
                        <v-card :loading="loadingAdresses">
                          <v-card-text>
                            <v-list dense subheader :disabled="loadingAdresses">
                              <v-subheader style="font-size: 14px"
                                >Addresses</v-subheader
                              >
                              <v-divider></v-divider>
                              <v-list-item
                                v-for="address in shipmentPartyAddresses"
                                :key="address.id"
                                @click="
                                  selectPartyAddress(address, party.roleValue)
                                "
                              >
                                <v-list-item-action class="mr-1">
                                  <v-icon
                                    x-small
                                    :color="
                                      address.id ==
                                      shipment[party.roleValue + 'AddressId']
                                        ? 'success'
                                        : 'grey'
                                    "
                                  >
                                    fiber_manual_record
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title style="font-size: 12px">{{
                                    address.alias
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    style="font-size: 11px"
                                    class="text-wrap"
                                    >{{
                                      address.description
                                    }}</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-if="shipmentPartyAddresses.length == 0"
                              >
                                <v-list-item-content class="text-center">
                                  <span style="font-size: 12px; color: grey"
                                    >No Addresses</span
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 11px">
                      <v-icon class="mr-1" x-small>groups</v-icon
                      >{{ party.role }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  <v-icon class="mr-2" small>group</v-icon>
                  Customer Relationships
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item
                  v-for="relationship in relationships"
                  :key="relationship.id"
                >
                  <v-list-item-action class="mr-1">
                    <v-avatar
                      size="30"
                      :color="
                        relationship.customer.logo ? 'white' : 'secondary'
                      "
                    >
                      <v-img
                        v-if="relationship.customer.logo"
                        contain
                        :src="relationship.customer.logo"
                      ></v-img>
                      <h3 v-else>
                        {{ relationship.customer.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 12px"
                      class="text-wrap"
                    >
                      {{ relationship.customer.name }}
                      <v-btn v-if="relationship.organisationRelationship" icon small @click="routeToContact(relationship.organisationRelationshipId)"><v-icon small>launch</v-icon></v-btn>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      class="text-wrap"
                      v-if="
                        relationship.organisationRelationship &&
                        relationship.organisationRelationship.clientStatus
                      "
                    >
                      <b>Client Status:</b>
                      <v-icon
                        class="ml-2 mr-1"
                        small
                        v-if="
                          relationship.organisationRelationship.clientStatus
                        "
                        :color="
                          getClientStatusColor(
                            relationship.organisationRelationship.clientStatus
                          )
                        "
                        >fiber_manual_record</v-icon
                      >
                      {{ relationship.organisationRelationship.clientStatus }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      v-if="
                        relationship.organisationRelationship.accountReference
                      "
                    >
                      <b>Account Reference:</b>
                      {{
                        relationship.organisationRelationship.accountReference
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: tooltipOn }">
                            <v-chip
                              v-on="{ ...on, ...tooltipOn }"
                              :loading="savingInvoiceStatus"
                              class="mx-1"
                              :color="
                                getInvoiceColor(relationship.invoiceStatus)
                              "
                              small
                              >{{
                                getInvoiceName(relationship.invoiceStatus)
                              }}</v-chip
                            >
                          </template>
                          <span style="font-size: 12px"
                            >Update Invoice Status</span
                          >
                        </v-tooltip>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(invoice, index) in invoiceStatusOptions"
                          :key="index"
                          :style="{
                            'border-left': `5px solid ${invoice.color}`,
                            'font-size': '12px',
                          }"
                          @click="
                            (relationship.invoiceStatus = invoice.value),
                              updateInvoiceStatus(relationship)
                          "
                          style="height: 30px"
                        >
                          <v-list-item-content class="text-left ml-0 pl-0">
                            <v-list-item-title>{{
                              invoice.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-container style="width: 100%">
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-list dense class="my-0 py-0" subheader>
                      <v-divider></v-divider>

                      <v-subheader style="font-size: 14px">
                        <v-icon class="mr-2" small>insights</v-icon>
                        Actions
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="processChange('CHANGE_POL')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="blue-grey"
                            >anchor</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Change Port of Load
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('CHANGE_DESTINATION')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="blue"> mode_of_travel </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          Change Destination
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('CHANGE_PARTY')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="teal"> groups </v-icon>
                        </v-list-item-action>
                        <v-list-item-title> Change Party </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('CHANGE_PROFILE')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="orange"> handshake </v-icon>
                        </v-list-item-action>
                        <v-list-item-title> Change Profile </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="processChange('CHANGE_VESSEL')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="blue-grey lighten-1">
                            directions_boat
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title> Change Vessel </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('MOVE_CONTAINER')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="amber darken-1"> swap_horiz </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          Move Container(s)
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('SHORT_SHIPMENT')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="red darken-1"> warning </v-icon>
                        </v-list-item-action>
                        <v-list-item-title> Short Shipment </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="processChange('CHANGE_SHIPPING_LINE')"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="purple"> hub </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          Change Shipping Line
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="changeShipmentUCRModal = true"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon :color="shipment.ucrNo ? 'green' : 'grey'"
                            >tag</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Change UCR Number
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="shipment.ucrNo"
                            style="font-size: 11px"
                          >
                            {{ shipment.ucrNo }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-list dense class="my-0 py-0" subheader>
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 14px">
                        <v-icon small class="mr-2">history</v-icon>
                        Shipment Changes</v-subheader
                      >
                      <v-divider></v-divider>
                      <v-list-item v-if="shipmentChangeLogs.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey">
                            No Changes
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-for="change in shipmentChangeLogs"
                        :key="change.id"
                        style="border-bottom: 0.1px solid grey"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small color="secondary" class="mr-2 pb-1"
                              >event_repeat</v-icon
                            >
                            {{ formatChangeType(change.type) }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="change.locode">
                            <v-chip outlined style="border: none" small>
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${change.locode
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ change.locode }}
                            </v-chip>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-else-if="change.vessel"
                            class="text-wrap"
                          >
                            <v-icon small color="grey" class="mr-2"
                              >directions_boat</v-icon
                            >
                            {{ change.vessel.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-else-if="change.changedOrganisation"
                            class="text-wrap"
                          >
                            <v-icon small color="grey" class="mr-2"
                              >business</v-icon
                            >
                            Party: {{ change.changedOrganisation.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            class="text-wrap"
                            v-if="change.responsibleOrganisation"
                          >
                            <v-icon small color="grey" class="mr-2"
                              >account_balance</v-icon
                            >
                            Responsible Party:
                            {{ change.responsibleOrganisation.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            class="text-wrap"
                            v-if="change.consigneeProfile"
                          >
                            <v-icon small color="grey" class="mr-2"
                              >handshake</v-icon
                            >
                            Profile:
                            {{ change.consigneeProfile.systemReference }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-if="change.reason"
                            class="text-wrap"
                          >
                            <v-icon small color="grey" class="mr-2"
                              >article</v-icon
                            >
                            {{ change.reason }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="change.user">
                            <v-chip outlined style="border: none" small>
                              <v-avatar size="16" left :color="'secondary'">
                                <v-icon color="white" x-small>person</v-icon>
                              </v-avatar>
                              {{ change.user.firstname }}
                              {{ change.user.surname }}
                            </v-chip>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-if="change.createdAt"
                          >
                            <span style="font-size: 10px; color: grey">
                              {{ formatDateTime(change.createdAt) }}</span
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8">
          <v-row no-gutters>
            <v-col cols="12" md="9">
              <v-card outlined class="infoCards ma-1 py-0">
                <v-card-text class="ma-0 pa-0">
                  <v-container class="my-0 py-0" style="width: 100%">
                    <v-row no-gutters>
                      <v-col cols="12" md="6">
                        <v-list dense class="my-0 py-0">
                          <v-subheader style="font-size: 14px">
                            <v-icon class="mr-2" small>account_balance</v-icon>
                            Payment Terms <v-spacer></v-spacer>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="primary"
                                  icon
                                  small
                                  :loading="loadingProfileIncoTerms"
                                  @click="editShipmentIncoTerm()"
                                >
                                  <v-icon small>edit</v-icon>
                                </v-btn>
                              </template>
                              Edit Inco Term
                            </v-tooltip>
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item style="height: 30px">
                            <v-list-item-action class="mr-1">
                              <v-icon color="grey" small
                                >currency_exchange</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px">
                                {{ shipment.shipmentIncoTerm }}
                              </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Inco Term
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="height: 30px">
                            <v-list-item-action class="mr-1">
                              <v-avatar
                                :color="
                                  shipment &&
                                  shipment.contractParty &&
                                  shipment.contractParty.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                size="24"
                              >
                                <v-img
                                  v-if="
                                    shipment &&
                                    shipment.contractParty &&
                                    shipment.contractParty.logo
                                  "
                                  :src="shipment.contractParty.logo"
                                  contain
                                ></v-img>
                                <h3 v-else-if="shipment.contractParty">
                                  {{ shipment.contractParty.name.charAt(0) }}
                                </h3>
                                <v-icon v-else small>article</v-icon>
                              </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                style="font-size: 12px"
                                v-if="shipment.contractParty"
                              >
                                {{ shipment.contractParty.name }}
                              </v-list-item-title>
                              <v-list-item-title style="font-size: 12px" v-else>
                                -
                              </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Contract Owner
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action class="mr-1">
                              <v-icon color="grey" small>location_on</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px" v-if="shipment.originParty">
                                {{ shipment.originParty.name }}
                              </v-list-item-title>
                              <v-list-item-title style="font-size: 12px" v-else>
                                -
                              </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Term: {{ shipment.originTerm }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                v-if="shipment.originInvoiceTo"
                              >
                                Invoice:
                                {{ formatPartyName(shipment.originInvoiceTo) }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="font-size: 11px">
                                Origin Charges
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action class="mr-1">
                              <v-icon color="grey" small>location_on</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px" v-if="shipment.freightParty">
                                {{ shipment.freightParty.name }}
                              </v-list-item-title>
                              <v-list-item-title style="font-size: 12px" v-else>
                                -
                              </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Term: {{ shipment.freightTerm }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                v-if="shipment.freightInvoiceTo"
                              >
                                Invoice:
                                {{ formatPartyName(shipment.freightInvoiceTo) }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="font-size: 11px">
                                Freight Charges
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action class="mr-1">
                              <v-icon color="grey" small>location_on</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px" v-if="shipment.destinationParty">
                                {{ shipment.destinationParty.name }}
                              </v-list-item-title>
                              <v-list-item-title style="font-size: 12px" v-else>
                                -
                              </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Term: {{ shipment.destinationTerm }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                v-if="shipment.destinationInvoiceTo"
                              >
                                Invoice:
                                {{
                                  formatPartyName(shipment.destinationInvoiceTo)
                                }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="font-size: 11px">
                                Destination Charges
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <!-- <v-list dense class="my-0 py-0">
                        </v-list> -->
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-list dense class="my-0 py-0">
                              <v-subheader style="font-size: 14px">
                                <v-icon class="mr-2" small>warning</v-icon>
                                File Risk
                              </v-subheader>
                              <v-divider></v-divider>
                              <v-col cols="6" class="text-center">
                                <v-container>
                                  <v-row justify="center">
                                    <v-col class="text-center">
                                      <v-progress-circular
                                        v-if="performance"
                                        rotate="270"
                                        :size="100"
                                        :width="12"
                                        :value="performance.risk"
                                        :color="
                                          performance.risk == 0
                                            ? 'grey'
                                            : performance.risk > 80
                                            ? 'red'
                                            : performance.risk > 70
                                            ? 'orange'
                                            : 'success'
                                        "
                                      >
                                        {{ performance.risk + "%" }}
                                      </v-progress-circular>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-list>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-list dense class="my-0 py-0">
                              <v-subheader style="font-size: 14px">
                                <v-icon class="mr-2" small>insights</v-icon>
                                Progress
                              </v-subheader>
                              <v-divider></v-divider>
                              <v-col cols="6" class="text-center">
                                <v-container>
                                  <v-row justify="center">
                                    <v-col class="text-center">
                                      <v-progress-circular
                                        v-if="performance"
                                        rotate="270"
                                        :size="100"
                                        :width="12"
                                        :value="performance.progress"
                                        :color="
                                          performance.progress == 0
                                            ? 'grey'
                                            : performance.progress < 20
                                            ? 'red'
                                            : performance.progress < 50
                                            ? 'orange'
                                            : performance.progress < 100
                                            ? 'blue'
                                            : 'success'
                                        "
                                      >
                                        {{ performance.progress + "%" }}
                                      </v-progress-circular>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-list>
                          </v-col>
                          <v-col cols="12">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 14px">
                              <v-icon class="mr-2" small
                                >directions_boat</v-icon
                              >
                              Vessel
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ shipment.vessel.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ formatDate(shipment.etd) }} -
                                  {{ formatDate(shipment.eta) }} -
                                  {{
                                    calculateDays(shipment.etd, shipment.eta)
                                  }}
                                  Days
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-slider
                              class="px-2"
                              v-model="voyageProgress"
                              inverse-label
                              :color="
                                voyageProgress == 0
                                  ? 'grey'
                                  : voyageProgress <
                                    calculateDays(shipment.etd, shipment.eta)
                                  ? 'blue'
                                  : 'green'
                              "
                              :thumb-size="24"
                              readonly
                              :min="0"
                              :max="calculateDays(shipment.etd, shipment.eta)"
                            >
                            </v-slider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-list class="my-0 py-0" dense>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" md="6">
                          <v-list dense class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 14px">
                              <v-icon class="mr-2" small>leaderboard</v-icon>
                              Shipment Summary <v-spacer></v-spacer>
                              <v-btn-toggle
                                v-if="
                                  shipmentProducts.length > 0 &&
                                  !loadingProducts
                                "
                                mandatory
                                v-model="selectedProductFilter"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn value="product" v-on="on" small>
                                      <v-icon small>list</v-icon>
                                    </v-btn>
                                  </template>
                                  View Product Summary
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn value="variety" v-on="on" small>
                                      <v-icon small>segment</v-icon>
                                    </v-btn>
                                  </template>
                                  View Variety Summary
                                </v-tooltip>
                              </v-btn-toggle>
                            </v-subheader>
                            <v-divider></v-divider>
                            <div
                              v-if="!shipment.breakBulkShipment"
                              class="ml-1"
                            >
                              {{ containers.length }} Containers -
                              {{ uniquePallets }} Pallets
                            </div>
                            <div v-else class="ml-1">
                              {{ uniquePallets }} Pallets
                            </div>
                            <div
                              v-for="(summaryItem, index) in cartonSummary"
                              :key="index"
                            >
                              <span class="ml-1">
                                {{ summaryItem.count }}
                                {{ summaryItem.packType }}(S)</span
                              >
                            </div>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="6" class="ma-0 pa-0">
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ containerNettWeight }} KG
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Total Nett Weight
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                                <v-col cols="12" md="6" class="ma-0 pa-0">
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ containerGrossWeight }} KG
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Total Gross Weight
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-container>

                            <v-list
                              dense
                              class="my-0 py-0"
                              style="max-height: 35vh; overflow-y: auto"
                              v-if="
                                shipmentProducts.length > 0 && !loadingProducts
                              "
                            >
                              <v-list-item
                                v-if="
                                  shipmentProducts.length == 0 &&
                                  !loadingProducts
                                "
                              >
                                <v-list-item-content class="text-center">
                                  <span style="color: grey"
                                    >No products listed.</span
                                  >
                                </v-list-item-content></v-list-item
                              >
                              <v-list-item
                                v-for="product in viewProductSummary"
                                :key="product.id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ product.product.name }}
                                    <span v-if="product.product.hsCode"
                                      >({{
                                        product.product.hsCode.alternateCode
                                      }})</span
                                    >
                                  </v-list-item-title>
                                  <div
                                    v-if="selectedProductFilter == 'product'"
                                  >
                                    <div v-if="binsAndCartons">
                                      <v-list-item-subtitle
                                        style="font-size: 12px"
                                      >
                                        <v-icon small class="mr-1"
                                          >widgets</v-icon
                                        >
                                        {{
                                          calculateProductBins(
                                            product.productId
                                          )
                                        }}
                                        BINS
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle
                                        style="font-size: 12px"
                                      >
                                        <v-icon small class="mr-1"
                                          >widgets</v-icon
                                        >
                                        {{
                                          calculateProductCartons(
                                            product.productId
                                          )
                                        }}
                                        CARTONS
                                      </v-list-item-subtitle>
                                    </div>
                                    <div v-else>
                                      <v-list-item-subtitle
                                        style="font-size: 12px"
                                      >
                                        <v-icon small class="mr-1"
                                          >widgets</v-icon
                                        >
                                        {{
                                          getPackType() == "BIN"
                                            ? calculateProductBins(
                                                product.productId
                                              )
                                            : calculateProductCartons(
                                                product.productId
                                              )
                                        }}
                                        {{ getPackType() }}
                                      </v-list-item-subtitle>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <v-list-item-subtitle
                                      style="font-size: 12px"
                                    >
                                      <v-icon small class="mr-1">widgets</v-icon
                                      >{{
                                        calculateVarietyProductCartons(
                                          product.varietyProductId
                                        )
                                      }}
                                      {{ getPackType() }}
                                    </v-list-item-subtitle>
                                  </div>

                                  <v-list-item-subtitle
                                    style="font-size: 12px"
                                    v-if="selectedProductFilter == 'product'"
                                  >
                                    <v-icon small class="mr-1">scale</v-icon>
                                    {{
                                      calculateProductNettWeight(
                                        product.productId
                                      )
                                    }}
                                    Kg Nett Weight
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    style="font-size: 12px"
                                    v-else
                                  >
                                    <v-icon small class="mr-1">scale</v-icon>
                                    {{
                                      calculateVarietyProductNettWeight(
                                        product.varietyProductId
                                      )
                                    }}
                                    Kg Nett Weight
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    style="font-size: 12px"
                                    v-if="selectedProductFilter == 'product'"
                                  >
                                    <v-icon small class="mr-1">scale</v-icon>
                                    {{
                                      calculateProductGrossWeight(
                                        product.productId
                                      )
                                    }}
                                    Kg Gross Weight
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    style="font-size: 12px"
                                    v-else
                                  >
                                    <v-icon small class="mr-1">scale</v-icon>
                                    {{
                                      calculateVarietyProductGrossWeight(
                                        product.varietyProductId
                                      )
                                    }}
                                    Kg Gross Weight
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    v-if="
                                      selectedProductFilter == 'product' &&
                                      product.productTree &&
                                      product.productTree.length > 0
                                    "
                                  >
                                    <v-breadcrumbs
                                      style="font-size: 12px"
                                      class="ma-0 pa-0"
                                      :items="product.productTree"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-breadcrumbs-item :disabled="true">
                                          <span style="font-size: 12px">{{
                                            item.hsCode
                                              ? item.name +
                                                ` (${item.hsCode.alternateCode})`
                                              : item.name
                                          }}</span>
                                        </v-breadcrumbs-item>
                                      </template>
                                      <template v-slot:divider>
                                        <v-icon small>chevron_right</v-icon>
                                      </template>
                                    </v-breadcrumbs>
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle
                                    v-else-if="
                                      selectedProductFilter == 'variety' &&
                                      product.varietyTree &&
                                      product.varietyTree.length > 0
                                    "
                                  >
                                    <v-breadcrumbs
                                      style="font-size: 12px"
                                      class="ma-0 pa-0"
                                      :items="product.varietyTree"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-breadcrumbs-item :disabled="true">
                                          <span style="font-size: 12px">{{
                                            item.hsCode
                                              ? item.name +
                                                ` (${item.hsCode.alternateCode})`
                                              : item.name
                                          }}</span>
                                        </v-breadcrumbs-item>
                                      </template>
                                      <template v-slot:divider>
                                        <v-icon small>chevron_right</v-icon>
                                      </template>
                                    </v-breadcrumbs>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-list
                              dense
                              class="my-0 py-0"
                              style="max-height: 35vh; overflow-y: auto"
                              v-else-if="
                                shipmentProducts.length == 0 && !loadingProducts
                              "
                            >
                              <v-list-item
                                v-if="
                                  containerProductSummary.length == 0 &&
                                  !loadingProducts
                                "
                              >
                                <v-list-item-content class="text-center">
                                  <span style="color: grey"
                                    >No products listed.</span
                                  >
                                </v-list-item-content></v-list-item
                              >
                              <v-list-item
                                v-for="product in containerProductSummary"
                                :key="product.id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ product.product.name }}
                                    <span v-if="product.product.hsCode"
                                      >({{
                                        product.product.hsCode.alternateCode
                                      }})</span
                                    >
                                  </v-list-item-title>

                                  <v-list-item-subtitle style="font-size: 12px">
                                    <v-icon x-small color="grey" class="mr-1"
                                      >scale</v-icon
                                    >
                                    {{ product.nettWeight }}
                                    Kg Nett Weight
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    <v-icon x-small color="grey" class="mr-1"
                                      >scale</v-icon
                                    >
                                    {{ product.grossWeight }}
                                    Kg Gross Weight
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-list>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-list dense class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 14px">
                              <v-icon class="mr-2" small>cancel</v-icon>
                              Service Failures <v-spacer></v-spacer>
                              <v-tooltip
                                top
                                v-if="shipment.documentationStatus == 'OPEN'"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    color="primary"
                                    text
                                    @click="addServiceFailure"
                                  >
                                    <v-icon>add_circle_outline</v-icon>
                                  </v-btn>
                                </template>
                                Add General Service Failure
                              </v-tooltip>
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list-item
                              v-for="failure in serviceFailures"
                              :key="failure.id"
                              @click="editServiceFailure(failure)"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  v-if="failure.shipmentDocumentType"
                                >
                                  {{
                                    failure.shipmentDocumentType
                                      .locationDocument.name
                                  }}
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                  General Service Failure
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-icon color="grey" class="mr-2" small
                                    >category</v-icon
                                  >
                                  {{ failure.serviceFailureCategory.name }}
                                  <span v-if="failure.serviceFailureSubcategory"
                                    >:
                                    {{
                                      failure.serviceFailureSubcategory.name
                                    }}</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="failure.createdBy"
                                  class="wrap-text"
                                >
                                  <v-icon color="grey" small class="mr-2"
                                    >person</v-icon
                                  >
                                  {{ failure.createdBy.firstname }}
                                  {{ failure.createdBy.surname }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="
                                    failure.responsibleParty ||
                                    failure.responsibleOrganisation
                                  "
                                  class="wrap-text"
                                >
                                  <v-icon color="grey" small class="mr-2"
                                    >business</v-icon
                                  >
                                  <span
                                    v-if="failure.responsibleOrganisation"
                                    >{{
                                      failure.responsibleOrganisation.name
                                    }}</span
                                  >
                                  <span v-else
                                    >Other: {{ failure.responsibleParty }}</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="wrap-text">
                                  <v-icon color="grey" small class="mr-2"
                                    >event</v-icon
                                  >{{ formatDate(failure.createdAt) }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="failure.comment"
                                  class="wrap-text"
                                >
                                  <v-icon color="grey" small class="mr-2"
                                    >chat</v-icon
                                  >
                                  {{ failure.comment }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              v-if="
                                !loadingServiceFailures &&
                                serviceFailures &&
                                serviceFailures.length == 0
                              "
                            >
                              <v-list-item-content class="text-center">
                                <span style="color: grey"
                                  >No service failures.</span
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined class="infoCards ma-1 py-0">
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">location_on</v-icon>
                      Locations
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="editCountryModal = true"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.originCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.originCountry.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Country of Origin
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="grey" small>edit</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.portOfLoadValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.portOfLoadCity }} ({{
                            shipment.portOfLoadValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Port of Load
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.portOfDischargeValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.portOfDischargeCity }} ({{
                            shipment.portOfDischargeValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Port of Discharge
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.finalDestinationValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.finalDestinationValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.finalDestinationCity }} ({{
                            shipment.finalDestinationValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Final Destination
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.destinationCountry"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.destinationCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.destinationCountry.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Country
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">event</v-icon>
                      Dates
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon :color="shipment.etd ? 'primary' : 'grey'"
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-x
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item-content
                            v-on="on"
                            style="cursor: pointer"
                          >
                            <v-list-item-title v-if="shipment.etd">
                              {{ shipment.etd }}
                            </v-list-item-title>
                            <v-list-item-title v-else>- </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 11px">
                              Estimated Departure
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <v-date-picker
                          v-model="shipment.etd"
                          @change="setDate('etd')"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <v-list-item-action>
                        <v-tooltip top v-if="shipment.etd">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              color="redPop"
                              @click="removeDate('etd')"
                            >
                              <v-icon small>close</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Clear ETD</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon :color="shipment.atd ? 'primary' : 'grey'"
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-x
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item-content
                            v-on="on"
                            style="cursor: pointer"
                          >
                            <v-list-item-title v-if="shipment.atd">
                              {{ shipment.atd }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 11px">
                              Actual Departure
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <v-date-picker
                          v-model="shipment.atd"
                          @change="setDate('atd')"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <v-list-item-action>
                        <v-tooltip top v-if="shipment.atd">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              color="redPop"
                              @click="removeDate('atd')"
                            >
                              <v-icon small>close</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Clear ATD</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon :color="shipment.eta ? 'primary' : 'grey'"
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-x
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item-content
                            v-on="on"
                            style="cursor: pointer"
                          >
                            <v-list-item-title v-if="shipment.eta">
                              {{ shipment.eta }}
                            </v-list-item-title>
                            <v-list-item-title v-else>- </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 11px">
                              Estimated Arrival
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <v-date-picker
                          v-model="shipment.eta"
                          @change="setDate('eta')"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <v-list-item-action>
                        <v-tooltip top v-if="shipment.eta">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              color="redPop"
                              @click="removeDate('eta')"
                            >
                              <v-icon small>close</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Clear ETA</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon :color="shipment.ata ? 'primary' : 'grey'"
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-x
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item-content
                            v-on="on"
                            style="cursor: pointer"
                          >
                            <v-list-item-title v-if="shipment.ata">
                              {{ shipment.ata }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 11px">
                              Actual Arrival
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <v-date-picker
                          v-model="shipment.ata"
                          @change="setDate('ata')"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <v-list-item-action>
                        <v-tooltip top v-if="shipment.ata">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              color="redPop"
                              @click="removeDate('ata')"
                            >
                              <v-icon small>close</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Clear ATA</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list
                      v-if="
                        shipment.stackDates && shipment.stackDates.length > 0
                      "
                      dense
                      class="my-0 py-0"
                    >
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 14px">
                        <v-icon class="mr-2" small>date_range</v-icon>
                        Stack Dates
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list-item
                        v-for="stack in shipment.stackDates"
                        :key="stack.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ stack.terminal }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="shipment.reefer && stack.reeferStackStart"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.reeferStackStartConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Reefer Stack Start:
                              <span v-if="stack.reeferStackStartConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Open: {{ formatDateTime(stack.reeferStackStart) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="shipment.reefer && stack.reeferStackFinish"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.reeferStackFinishConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Reefer Stack Close:
                              <span v-if="stack.reeferStackFinishConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Close: {{ formatDateTime(stack.reeferStackFinish) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="!shipment.reefer && stack.dryStackStart"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.dryStackStartConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Dry Stack Open:
                              <span v-if="stack.dryStackStartConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Open: {{ formatDateTime(stack.dryStackStart) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="!shipment.reefer && stack.dryStackFinish"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.dryStackFinishConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Dry Stack Close:
                              <span v-if="stack.dryStackFinishConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Close: {{ formatDateTime(stack.dryStackFinish) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card class="infoCards mx-0 mb-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>notifications</v-icon
                      >Subscribers
                      <v-btn icon color="primary" @click="editSubscribers()">
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="shipment.subscribers.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey; font-size: 12px">
                          No subscribers
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      style="height: 30px"
                      v-for="user in shipment.subscribers"
                      :key="user.id"
                    >
                      <v-list-item-action class="mr-2">
                        <v-avatar
                          :color="user.user.avatar ? 'white' : 'secondary'"
                          size="28"
                        >
                          <v-img
                            contain
                            v-if="user.user.avatar"
                            :src="user.user.avatar"
                          ></v-img>
                          <h3 v-else>
                            {{ user.user.firstname.charAt(0) }}
                          </h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ user.user.firstname }} {{ user.user.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ user.user.emailAddress }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              color="redPop"
                              @click="removeSubscriber(user.id)"
                              :loading="removingSubscriber == user.id"
                            >
                              <v-icon small>delete</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">Remove subscriber</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="infoCards ma-1 py-0">
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">widgets</v-icon>
                      <div
                        style="font-size: 14px"
                        v-if="!shipment.breakBulkShipment"
                      >
                        Containers
                      </div>
                      <div style="font-size: 14px" v-else>Breakbulk Decks</div>
                      <v-spacer></v-spacer>
                      <v-chip>
                        <v-icon small> search </v-icon>
                        <v-text-field
                          placeholder="Search"
                          class="mb-1"
                          hide-details
                          rounded
                          clearable
                          dense
                          style="width: 300px"
                          v-model="searchContainers"
                        ></v-text-field>
                      </v-chip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <Containers
                      :shipment="shipment"
                      v-if="shipment"
                      :data="filterContainers"
                      :refreshTableKey="refreshKey"
                      @refreshContainers="$emit('refreshContainers')"
                      @refreshContainer="refreshContainer"
                      @refreshContainerContent="refreshContainerContent"
                    />
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="shipmentChangeModal"
      :max-width="shipmentChangeType == 'CHANGE_PROFILE' ? '1400px' : '750px'"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <ShipmentChange
        :shipment="shipment"
        :containers="containers"
        :type="shipmentChangeType"
        @close="shipmentChangeModal = false"
        @getShipmentChangeLogs="getShipmentChangeLogs"
        :documents="documents"
        :customerId="customerId"
        :relationships="relationships"
        @refresh="$emit('refresh'), (shipmentChangeModal = false)"
      />
    </v-dialog>

    <v-dialog v-model="changeShipmentUCRModal" max-width="30vw">
      <v-card>
        <v-toolbar flat>
          <v-card-title>Update UCR Number</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="savingShipmentUCR"
            text
            @click="saveShipmentUCR()"
            color="primary"
            ><v-icon>save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="shipment.ucrNo"
            dense
            outlined
            small
            label="UCR Number"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editCountryModal" width="400px">
      <v-card :loading="savingCountry">
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px">
            Country of Origin
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchCountry"
            outlined
            dense
            clearable
          ></v-text-field>
          <v-list dense style="max-height: 500px; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="updateCountry(country)"
            >
              <v-list-item-action>
                <v-avatar size="20" v-if="country.iso2">
                  <v-img
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px"> No countries found </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="serviceFailureModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent"
          ><v-toolbar-title style="font-size: 16px"
            >Service Failure</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            id="service-failure-save"
            color="primary"
            icon
            :loading="savingServiceFailure"
            :disabled="
              !serviceFailureItem.serviceFailureCategoryId ||
              (!serviceFailureItem.responsibleOrganisationId &&
                !serviceFailureItem.responsibleParty)
            "
            @click="saveServiceFailure"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            text
            @click="(serviceFailureModal = false), (serviceFailureItem = {})"
            >X</v-btn
          ></v-toolbar
        >
        <v-card-text>
          <v-autocomplete
            id="service-failure-category"
            label="Category*"
            :items="serviceFailureCategories"
            v-model="serviceFailureItem.serviceFailureCategoryId"
            outlined
            dense
            clearable
            item-text="name"
            item-value="id"
          ></v-autocomplete>
          <v-autocomplete
            id="service-failure-responsible-party"
            :items="availableParties"
            v-model="serviceFailureItem.responsibleOrganisationId"
            label="Responsible Party"
            outlined
            dense
            clearable
            item-text="name"
            item-value="value"
          ></v-autocomplete>
          <v-text-field
            id="service-failure-specific-party"
            v-if="!serviceFailureItem.responsibleOrganisationId"
            v-model="serviceFailureItem.responsibleParty"
            outlined
            dense
            clearable
            label="Specify Party"
          ></v-text-field>
          <v-textarea
            id="service-failure-description"
            outlined
            rows="8"
            v-model="serviceFailureItem.comment"
            placeholder="Description"
          ></v-textarea>
          <v-row class="mt-2" justify="center">
            <v-btn
              id="service-failure-delete"
              @click="deleteServiceFailure()"
              :loading="deletingServiceFailure"
              v-if="
                serviceFailureItem.id &&
                serviceFailureItem.organisationId == $store.state.currentOrg.id
              "
              color="red"
              style="text-transform: none"
              ><v-icon>delete</v-icon> Delete</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipmentProfileModal" width="1200">
      <ShipmentProfile
        :profile="profile"
        @close="shipmentProfileModal = false"
      />
    </v-dialog>

    <v-dialog v-model="incoTermModal" width="800px">
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title style="font-size: 16px">
            Available Terms
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="incoTermModal = false"
            ><v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-list dense subheader class="mx-0 px-0">
            <v-card
            class="my-2 py-2"
            flat
            style="background-color: var(--v-greyRaised-base) !important"
            v-for="(itemProfile) in availableIncoTerms"
            :key="itemProfile.id"
                  :disabled="itemProfile.contractPartyId !== shipment.contractPartyId"
              @click="setShipmentIncoTerm(itemProfile)"
          >
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.contractParty &&
                            itemProfile.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.contractParty &&
                              itemProfile.contractParty.logo
                            "
                            :src="itemProfile.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.contractParty">
                            {{ itemProfile.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.contractParty"
                        >
                          <span
                            v-if="itemProfile.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ itemProfile.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="itemProfile.comment"
                        >
                          {{ itemProfile.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.originParty &&
                            itemProfile.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.originParty &&
                              itemProfile.originParty.logo
                            "
                            :src="itemProfile.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.originParty">
                            {{ itemProfile.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.originParty"
                        >
                          <span
                            v-if="itemProfile.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.freightParty &&
                            itemProfile.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.freightParty &&
                              itemProfile.freightParty.logo
                            "
                            :src="itemProfile.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.freightParty">
                            {{ itemProfile.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.freightParty"
                        >
                          <span
                            v-if="itemProfile.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.destinationParty &&
                            itemProfile.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.destinationParty &&
                              itemProfile.destinationParty.logo
                            "
                            :src="itemProfile.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.destinationParty">
                            {{ itemProfile.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.destinationParty"
                        >
                          <span
                            v-if="itemProfile.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
            <!-- <v-list-item
              v-for="term in availableIncoTerms"
              :key="term.id"
              @click="setShipmentIncoTerm(term)"
            >
              <v-list-item-action class="mr-1">
                <v-chip small>
                  {{ term.incoTerm }}
                </v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon color="grey" class="mr-2" x-small>description</v-icon>
                  Contract: {{ formatPartyName(term.contractOwner) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon color="grey" class="mr-2" x-small>location_on</v-icon>
                  Origin: {{ formatPartyName(term.originCharge) }}
                  <v-chip x-small label outlined>
                    <v-icon x-small left>label</v-icon>
                    {{ term.originTerm }}
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon color="grey" class="mr-2" x-small
                    >mode_of_travel</v-icon
                  >
                  Freight: {{ formatPartyName(term.freightCharge) }}
                  <v-chip x-small label outlined>
                    <v-icon x-small left>label</v-icon>
                    {{ term.paymentTerm }}
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon color="grey" class="mr-2" x-small>location_on</v-icon>
                  Destination: {{ formatPartyName(term.destinationCharge) }}
                  <v-chip x-small label outlined>
                    <v-icon x-small left>label</v-icon>
                    {{ term.destinationTerm }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userModal" width="500px" persistent>
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Add Subscribers
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="selectedUsers.length == 0"
            @click="saveSubscribers()"
            :loading="savingUser"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn icon color="redPop" @click="userModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="userSearch"
            outlined
            dense
            hide-details
            placeholder="Search"
            prepend-inner-icon="search"
            clearable
          ></v-text-field>
          <v-list
            dense
            style="
              max-height: 50vh;
              overflow-y: auto;
              background: transparent;
              background-color: transparent;
            "
          >
            <v-list-item v-if="filteredUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="user in filteredUsers"
              :key="user.id"
              @click="addSubscriber(user.userId)"
              style="height: 30px"
            >
              <v-list-item-action>
                <v-avatar
                  :color="user.user.avatar ? 'white' : 'secondary'"
                  size="36"
                >
                  <v-img
                    v-if="user.user.avatar"
                    referrerpolicy="no-referrer"
                    :src="user.user.avatar"
                    contain
                  ></v-img>
                  <h3 v-else-if="user.user.firstname">
                    {{ user.user.firstname.charAt(0) }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="selectedUsers.includes(user.userId)" color="green"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Containers from "./Containers.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import ShipmentChange from "./ShipmentChange.vue";
import ShipmentProfile from "./ViewShipmentProfile.vue";
export default {
  name: "Overview",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    containers: {
      type: Array,
      required: true,
    },
    performance: {
      type: Object,
      required: true,
    },
    refreshKey: {
      type: Number,
    },
  },
  components: {
    Containers,
    ShipmentChange,
    ShipmentProfile,
  },
  data: () => ({
    availableIncoTerms: [],
    changeShipmentUCRModal: false,
    countries: [],
    documents: [],
    editCountryModal: false,
    incoTermModal: false,
    invoiceStatusOptions: [
      { name: "Not Ready", value: "NOTREADY", color: "grey", icon: "schedule" },
      {
        name: "Ready to Invoice",
        value: "READY",
        color: "#DC7633",
        icon: "pending",
      },
      { name: "Invoiced", value: "INVOICED", color: "#5DADE2 ", icon: "paid" },
      { name: "Paid", value: "PAID", color: "#229954", icon: "task_alt" },
    ],
    loadingAdresses: false,
    loadingProducts: false,
    loadingProfile: false,
    loadingProfileIncoTerms: false,
    loadingServiceFailures: false,
    loadingShipmentChange: false,
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier Party",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify Party",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
    ],
    orgUsers: [],
    profile: null,
    refreshTableKey: 200,
    removingSubscriber: null,
    routeKey: 100,
    savingCountry: false,
    savingInvoiceStatus: false,
    savingServiceFailure: false,
    savingShipmentUCR: false,
    savingUser: false,
    searchContainers: null,
    searchCountry: null,
    selectedProductFilter: "product",
    selectedUsers: [],
    serviceFailureCategories: [],
    serviceFailures: [],
    serviceFailureModal: false,
    serviceFailureItem: {},
    shipmentChangeType: null,
    shipmentChangeModal: false,
    shipmentChangeLogs: [],
    shipmentPartyAddresses: [],
    shipmentProducts: [],
    shipmentProfileModal: false,
    userModal: false,
    userSearch: null,
    voyageProgress: 0,
  }),
  watch: {
    "shipment.id": {
      handler() {
        if (this.shipment && this.shipment.id) {
          this.getShipmentProducts();
          this.getShipmentChangeLogs();
          this.getServiceFailures();
          this.getServiceFailureCategories();
          if (new Date(this.shipment.etd) < new Date()) {
            let difference = this.calculateDays(
              new Date(this.shipment.etd),
              new Date()
            );
            let shipmentTotal = this.calculateDays(
              new Date(this.shipment.etd),
              new Date(this.shipment.eta)
            );
            difference =
              difference > shipmentTotal ? shipmentTotal : difference;
            this.voyageProgress = difference > 0 ? difference : 0;
          } else {
            this.voyageProgress = 0;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    availableParties() {
      let result = [];
      let parties = [
        "shipper",
        "onBehalfShipper",
        "forwarder",
        "onBehalfForwarder",
        "consignee",
        "onBehalfConsignee",
        "buyer",
        "onBehalfBuyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        if (this.shipment[parties[i]]) {
          let findExisting = result.find(
            (x) => x.value == this.shipment[parties[i] + "Id"]
          );

          if (!findExisting) {
            result.push({
              name: this.shipment[parties[i]].name,
              value: this.shipment[parties[i] + "Id"],
            });
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(
            (x) => x.value == this.relationships[i].customerId
          );
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId,
            });
          }
        }
      }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    cartonSummary() {
      let palletPacktypes = [...new Set(this.pallets.map((x) => x.packType))];
      let result = [];
      for (let i = 0; i < palletPacktypes.length; i++) {
        let pallets = this.pallets.filter(
          (x) => x.packType == palletPacktypes[i]
        );
        let cartons = pallets
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        result.push({
          packType: palletPacktypes[i] ?? "CARTON" + "(S)",
          count: cartons,
        });
      }
      return result;
    },
    binsAndCartons() {
      let palletPacktypes = this.pallets.map((x) => x.packType);
      if (
        palletPacktypes.includes("BIN") &&
        palletPacktypes.includes("CARTON")
      ) {
        return true;
      } else {
        return false;
      }
    },
    containerNettWeight() {
      let pallets = this.pallets;
      if (pallets.length > 0) {
        return pallets
          .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        if (this.containers) {
          let products = [].concat.apply(
            [],
            this.containers.map((x) =>
              x.containerProducts.filter((y) => y.nettWeight)
            )
          );
          return products
            .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
        } else {
          return 0;
        }
      }
    },
    containerGrossWeight() {
      if (this.containers) {
        let pallets = this.pallets;
        if (pallets.length > 0) {
          return pallets
            .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
        } else {
          let products = [].concat.apply(
            [],
            this.containers.map((x) =>
              x.containerProducts.filter((y) => y.grossWeight)
            )
          );
          return products
            .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
        }
      } else {
        return 0;
      }
    },
    customerId() {
      if (this.relationships.length > 0) {
        let find = this.relationships.find((x) => x.customerId);
        return find ? find.customerId : null;
      } else {
        return null;
      }
    },
    filterContainers() {
      if (this.containers) {
        let result = this.containers;
        if (this.searchContainers) {
          result = result.filter(
            (x) =>
              (x.containerNo &&
                x.containerNo
                  .toLowerCase()
                  .includes(this.searchContainers.toLowerCase())) ||
              (x.sealNo &&
                x.sealNo
                  .toLowerCase()
                  .includes(this.searchContainers.toLowerCase())) ||
              (x.customerContainerRef &&
                x.customerContainerRef
                  .toLowerCase()
                  .includes(this.searchContainers.toLowerCase())) ||
              (x.billOfLadingNo &&
                x.billOfLadingNo
                  .toLowerCase()
                  .includes(this.searchContainers.toLowerCase())) ||
              (x.booking &&
                x.booking.carrierReferenceNumber &&
                x.booking.carrierReferenceNumber
                  .toLowerCase()
                  .includes(this.searchContainers.toLowerCase()))
          );
        }
        return result;
      } else {
        return [];
      }
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountry) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      result.sort((a, b) => a.name.localeCompare(b.name));
      return result;
    },
    listedParties() {
      let roles = this.partyTypes;
      let result = [];
      for (let role of roles) {
        if (this.shipment[role.value]) {
          let obj = {
            id: this.shipment[role.value].id,
            logo: this.shipment[role.value].logo,
            name: this.shipment[role.value].name,
            alias: this.shipment[role.value].alias,
            role: role.name,
            roleValue: role.value,
            linkedValue: role.linkedValue,
          };
          result.push(obj);
        }
      }
      return result;
    },
    filteredUsers() {
      let result = this.orgUsers;
      result = result.filter(
        (x) =>
          !this.shipment.subscribers.map((y) => y.userId).includes(x.userId)
      );
      if (this.userSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.userSearch.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.userSearch.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.userSearch.toLowerCase())
        );
      }
      result.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      return result;
    },
    pallets() {
      if (this.containers) {
        let pallets = [].concat.apply(
          [],
          this.containers.map((x) => x.containerPallets)
        );
        return pallets;
      } else {
        return [];
      }
    },
    uniquePallets() {
      if (this.containers) {
        let pallets = [].concat.apply(
          [],
          this.containers.map((x) => x.containerPallets)
        );
        if (pallets.length > 0) {
          return [...new Set(pallets.map((x) => x.sscc))].length;
        } else {
          let products = [].concat.apply(
            [],
            this.containers.map((x) =>
              x.containerProducts.filter((y) => y.pallets)
            )
          );
          return products.map((x) => x.pallets).reduce((a, b) => a + b, 0);
        }
      } else {
        return 0;
      }
    },
    containerProductSummary() {
      if (this.containers) {
        let products = [].concat.apply(
          [],
          this.containers.map((x) => x.containerProducts)
        );
        products.forEach((x) => {
          x.hash = x.productId + x.hazardous + x.industrial + x.organic;
        });
        let uniqueHash = [...new Set(products.map((x) => x.hash))];
        let result = [];
        for (let i = 0; i < uniqueHash.length; i++) {
          let filtered = products.filter((x) => x.hash == uniqueHash[i]);
          let uniqueQuantities = [
            ...new Set(filtered.map((x) => x.quantityType)),
          ];
          let detail = filtered[0];
          let obj = {
            id: i + 1,
            product: detail.product,
            hasCode: detail.hsCode,
            organic: detail.organic,
            industrial: detail.industrial,
            hazardous: detail.hazardous,
            grossWeight: filtered
              .filter((x) => x.grossWeight)
              .map((x) => (parseFloat(x.grossWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0),
            nettWeight: filtered
              .filter((x) => x.nettWeight)
              .map((x) => (parseFloat(x.nettWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0),
          };
          result.push(obj);
        }
        return result;
      }
      return [];
    },
    viewProductSummary() {
      let result = [];
      if (this.selectedProductFilter == "product") {
        let uniqueProducts = [
          ...new Set(this.shipmentProducts.map((x) => x.productId)),
        ];
        uniqueProducts.forEach((x) => {
          let product = this.shipmentProducts.find((y) => y.productId == x);
          result.push(product);
        });
      } else {
        let uniqueProducts = [
          ...new Set(this.shipmentProducts.map((x) => x.varietyProductId)),
        ];
        uniqueProducts.forEach((x) => {
          let product = this.shipmentProducts.find(
            (y) => y.varietyProductId == x
          );
          result.push(product);
        });
      }
      return result;
    },
  },
  async created() {
    this.countries = await this.$API.searchCountriesBasic({});
    this.getOrganisationUsers();
  },
  methods: {
    addSubscriber(userId) {
      let find = this.selectedUsers.findIndex((x) => x == userId);
      if (find == -1) {
        this.selectedUsers.push(userId);
      } else {
        this.selectedUsers.splice(find, 1);
      }
    },
    addServiceFailure() {
      this.serviceFailureItem = {
        responsibleOrganisationId: this.$store.state.currentOrg.id,
      };
      this.serviceFailureModal = true;
    },
    calculateDays(start, end) {
      start = moment(start);
      end = moment(end);
      let difference = end.diff(start, "days");
      let count = difference;
      return count + 1;
    },
    calculatePercentage(start, end) {
      let result = parseInt(start) / parseInt(end);
      return Math.round(result * 100);
    },
    calculateProductCartons(productId) {
      let pallets = this.pallets.filter(
        (x) => x.productId == productId && x.packType == "CARTON"
      );
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.pallets.filter(
        (x) => x.productId == productId && x.packType == "BIN"
      );
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductNettWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.nettWeight), 0)
        .toFixed(2);
    },
    calculateVarietyProductNettWeight(varietyId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == varietyId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.nettWeight), 0)
        .toFixed(2);
    },
    calculateProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.grossWeight), 0)
        .toFixed(2);
    },
    calculateVarietyProductCartons(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateVarietyProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.grossWeight), 0)
        .toFixed(2);
    },
    constructAddressLine(addressLine, address) {
      if (address) {
        switch (addressLine) {
          case 1:
            return [address.addressLine1, address.addressLine2]
              .filter(Boolean)
              .join(", ");
          case 2:
            return [address.addressLine3, address.addressLine4]
              .filter(Boolean)
              .join(", ");
          case 3:
            return [
              address.town,
              address.subdivision,
              address.country,
              address.postalCode,
            ]
              .filter(Boolean)
              .join(", ");
        }
      }
    },
    concatenateAddress(address) {
      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address),
      ];
      return result.filter(Boolean).join(", ");
    },
    deleteServiceFailure() {
      this.deletingServiceFailure = true;
      this.$confirm(
        "Are you sure you want to delete this service failure?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.serviceFailureItem.isDeleted = true;
          this.serviceFailureItem.isActive = false;
          await this.$API.updateServiceFailure(this.serviceFailureItem);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getServiceFailures();
          this.serviceFailureModal = false;
          this.serviceFailureItem = {};
          this.deletingServiceFailure = false;
        })
        .catch(() => {
          this.deletingServiceFailure = false;
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editServiceFailure(failure) {
      if (failure.type === "SHIPMENT") {
        this.serviceFailureItem = failure;
        this.serviceFailureModal = true;
      } else {
        this.$message.error(
          "You are only able to manage document service failures under the 'Documents' tab."
        );
      }
    },
    async editShipmentIncoTerm() {
      this.loadingProfileIncoTerms = true;
      this.availableIncoTerms = await this.$API.getConsigneeProfileIncoTerms(
        this.shipment.consigneeProfileId
      );
      this.loadingProfileIncoTerms = false;
      this.incoTermModal = true;
    },
    editSubscribers() {
      this.userSearch = null;
      this.userModal = true;
    },
    formatPartyName(type) {
      if (this.shipment[type]) {
        return this.shipment[type].name;
      } else {
        return type ? this.$Format.capitalizeFirstLetter(type) : null;
      }
    },
    formatChangeType(type) {
      switch (type) {
        case "CHANGE_SHIPPER":
          return "Change of Shipper";
        case "ORIGINAL_SHIPPER":
          return "Original Shipper";
        case "CHANGE_ON_BEHALF_SHIPPER":
          return "Change of On Behalf Shipper";
        case "ORIGINAL_ON_BEHALF_SHIPPER":
          return "Original On Behalf Shipper";
        case "ORIGINAL_FORWARDER":
          return "Original Forwarder";
        case "CHANGE_FORWARDER":
          return "Change of Forwarder";
        case "ORIGINAL_ON_BEHALF_FORWARDER":
          return "Original On Behalf Forwarder";
        case "CHANGE_ON_BEHALF_FORWARDER":
          return "Change of On Behalf Forwarder";
        case "ORIGINAL_BUYER":
          return "Original Buyer";
        case "CHANGE_BUYER":
          return "Change of Buyer";
        case "ORIGINAL_ON_BEHALF_BUYER":
          return "Original On Behalf Buyer";
        case "CHANGE_ON_BEHALF_BUYER":
          return "Change of On Behalf Buyer";
        case "CHANGE_SHIPPING_LINE":
          return "Change of Shipping Line";
          case "CHANGE_POL":
          return "Change POL";
          case "ORIGINAL_POL":
          return "Original POL";
        case "CHANGE_CONSIGNEE":
          return "Change of Consignee";
        case "ORIGINAL_CONSIGNEE":
          return "Original Consignee";
        case "CHANGE_ON_BEHALF_CONSIGNEE":
          return "Change of On Behalf Consignee";
        case "ORIGINAL_ON_BEHALF_CONSIGNEE":
          return "Original On Behalf Consignee";
        case "ORIGINAL_FIRST_NOTIFY":
          return "Original First Notify";
        case "CHANGE_FIRST_NOTIFY":
          return "Change of First Notify";
        case "ORIGINAL_SECOND_NOTIFY":
          return "Original Second Notify";
        case "CHANGE_SECOND_NOTIFY":
          return "Change of Second Notify";
        case "ORIGINAL_COURIER_PARTY":
          return "Original Courier Party";
        case "CHANGE_COURIER_PARTY":
          return "Change of Courier Party";
        case "CHANGE_DESTINATION":
          return "Change Destination";
        case "ORIGINAL_DESTINATION":
          return "Original Destination";
        case "CHANGE_POD":
          return "Change POD";
        case "ORIGINAL_POD":
          return "Original POD";
        case "CHANGE_VESSEL":
          return "Change Vessel";
        case "ORIGINAL_VESSEL":
          return "Original Vessel";
        case "REMOVE_SHIPPER":
          return "Remove Shipper";
        case "REMOVE_ON_BEHALF_SHIPPER":
          return "Remove On Behalf Shipper";
        case "REMOVE_FORWARDER":
          return "Remove Forwarder";
        case "REMOVE_ON_BEHALF_FORWARDER":
          return "Remove On Behalf Forwarder";
        case "REMOVE_BUYER":
          return "Remove Buyer";
        case "REMOVE_ON_BEHALF_BUYER":
          return "Remove On Behalf Buyer";
        case "REMOVE_CONSIGNEE":
          return "Remove Consignee";
        case "REMOVE_ON_BEHALF_CONSIGNEE":
          return "Remove On Behalf Consignee";
        case "REMOVE_FIRST_NOTIFY":
          return "Remove First Notify";
        case "REMOVE_SECOND_NOTIFY":
          return "Remove Second Notify";
        case "REMOVE_COURIER_PARTY":
          return "Remove Courier Party";
        case "ORIGINAL_PROFILE":
          return "Original Profile";
        case "CHANGE_PROFILE":
          return "Change Profile";
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getOrganisationUsers() {
      this.orgUsers = await this.$API.getOrganisationUsers();
    },
    getPackType() {
      let type = this.pallets.find((x) => x.packType);
      return type ? type.packType + "(S)" : "CARTON(S)";
    },
    getInvoiceIcon(status) {
      let find = this.invoiceStatusOptions.find((x) => x.value == status);
      return find ? find.icon : "schedule";
    },
    getInvoiceColor(status) {
      let find = this.invoiceStatusOptions.find((x) => x.value == status);
      return find ? find.color : "grey";
    },
    getInvoiceName(status) {
      let find = this.invoiceStatusOptions.find((x) => x.value == status);
      return find ? find.name : null;
    },
    async getPartyAddresses(party, id) {
      this.loadingAdresses = true;
      if (
        party &&
        party.organisationAddresses &&
        party.organisationAddresses.length > 0
      ) {
        this.shipmentPartyAddresses = party.organisationAddresses.map((x) => ({
          ...x,
          description: this.concatenateAddress(x),
        }));
      } else {
        console.log(party);
        party.organisationAddresses = await this.$API.listOrganisationAddresses(
          id
        );
        this.shipmentPartyAddresses = party.organisationAddresses.map((x) => ({
          ...x,
          description: this.concatenateAddress(x),
        }));
      }
      this.loadingAdresses = false;
    },
    async getShipmentProducts() {
      this.loadingProducts = true;
      this.shipmentProducts = await this.$API.getShipmentProducts(
        this.shipment.id
      );
      this.loadingProducts = false;
    },
    async getServiceFailures() {
      this.loadingServiceFailures = true;
      this.serviceFailures = await this.$API.getServiceFailureByShipment(
        this.shipment.id
      );
      this.loadingServiceFailures = false;
    },
    async getServiceFailureCategories() {
      this.serviceFailureCategories =
        await this.$API.getServiceFailureCategories();
    },
    async getShipmentChangeLogs() {
      this.shipmentChangeLogs = await this.$API.getShipmentChanges(
        this.shipment.id
      );
    },
    async processChange(type) {
      this.loadingShipmentChange = true;
      this.shipmentChangeType = type;
      this.loadingShipmentChange = false;
      this.shipmentChangeModal = true;
    },
    refreshContainer(id) {
      this.$emit("refreshContainer", id);
    },
    refreshContainerContent(id) {
      this.$emit("refreshContainerContent", id);
    },
    async removeSubscriber(id) {
      this.removingSubscriber = id;
      let result = await this.$API.updateShipmentSubscribers({
        id: id,
        isActive: false,
        isDeleted: true,
      });
      this.$emit("removeSubscriber", id);
      this.$message.success("Successfully removed subscriber!");
      this.removingSubscriber = null;
    },
    routeToContact(id) {
      this.$router.push("/contact/" + id);
    },
    async saveServiceFailure() {
      this.savingServiceFailure = true;
      if (this.serviceFailureItem.id) {
        await this.$API.updateServiceFailure(this.serviceFailureItem);
      } else {
        this.serviceFailureItem.type = "shipment";
        this.serviceFailureItem.shipmentId = this.shipment.id;
        await this.$API.createServiceFailure(this.serviceFailureItem);
      }
      this.getServiceFailures();
      this.serviceFailureModal = false;
      this.serviceFailureItem = {};
      this.savingServiceFailure = false;
    },
    async saveShipmentUCR() {
      this.savingShipmentUCR = true;
      let obj = {
        id: this.shipment.id,
        ucrNo: this.shipment.ucrNo,
      };
      await this.$API.saveShipmentUCR(obj);
      this.savingShipmentUCR = false;
      this.changeShipmentUCRModal = false;
      this.$message.success("Successfully updated UCR number!");
    },
    async saveSubscribers() {
      this.savingUser = true;
      let request = this.selectedUsers.map((x) => ({
        userId: x,
        shipmentId: this.shipment.id,
      }));
      let result = await this.$API.createShipmentSubscribers({
        batch: request,
      });
      if (result && result.length) {
        for (let user of result) {
          let findUser = this.orgUsers.find((x) => x.userId == user.userId);
          user.user = findUser.user;
        }
        this.$emit("setSubscribers", result);
      }
      this.$message.success("Successfully added subscribers!");
      this.userModal = false;
      this.selectedUsers = [];
      this.savingUser = false;
    },
    removeDate(type) {
      this.$emit("dateChange", { type, date: null });
    },
    setDate(type) {
      this.$emit("dateChange", { type, date: this.shipment[type] });
    },
    async selectPartyAddress(address, partyType) {
      this.shipment[partyType + "AddressId"] = address.id;
      this.shipment[partyType + "Address"] = address;
      await this.$API.updateShipment(this.shipment);
      this.$message.success("Successfully updated address");
    },
    async setShipmentIncoTerm(term) {
      this.shipment.incoTermId = term.id;
      this.shipment.shipmentIncoTerm = term.incoTerm;
      this.shipment.incoTermContractOwner = term.contractOwner;
      if (term.contractOwner != "customer") {
        this.shipment.contractPartyId =
          this.shipment[term.contractOwner + "Id"];
        this.shipment.contractParty = this.shipment[term.contractOwner];
      } else if (this.relationships.length > 0) {
        this.shipment.contractPartyId = this.relationships[0].customerId;
        this.shipment.contractParty = this.relationships[0].customer;
      }

      this.shipment.originCharge = term.originCharge;
      this.shipment.destinationCharge = term.destinationCharge;
      this.shipment.freightCharge = term.freightCharge;
      this.shipment.paymentTerm = term.paymentTerm;
      this.shipment.freightTerm = term.paymentTerm;
      this.shipment.originTerm = term.originTerm;
      this.shipment.destinationTerm = term.destinationTerm;
      this.shipment.contractOwner = term.contractOwner;
      this.shipment.originInvoiceTo = term.originInvoiceTo;
      this.shipment.destinationInvoiceTo = term.destinationInvoiceTo;
      this.shipment.freightInvoiceTo = term.freightInvoiceTo;
      this.shipment.incoTerm = term;
      this.incoTermModal = false;
      await this.$API.updateShipment({
        id: this.shipment.id,
        incoTermContractOwner: term.contractOwner,
        incoTermId: term.id,
        contractPartyId: this.shipment[term.contractOwner + "Id"],
        shipmentIncoTerm: term.incoTerm,
        originCharge: term.originCharge,
        destinationCharge: term.destinationCharge,
        freightCharge: term.freightCharge,
        originTerm: term.originTerm,
        paymentTerm: term.paymentTerm,
        freightTerm: term.paymentTerm,
        destinationTerm: term.destinationTerm,
        contractOwner: term.contractOwner,
        originInvoiceTo: term.originInvoiceTo,
        destinationInvoiceTo: term.destinationInvoiceTo,
        freightInvoiceTo: term.freightInvoiceTo,
      });
      this.$message.success("Successfully updated Inco Term");
    },
    async updateInvoiceStatus(relationship) {
      this.savingInvoiceStatus = true;
      await this.$API.updateShipmentRelationship(relationship);
      this.$message.success("Successfully updated invoice status");
      this.savingInvoiceStatus = false;
    },
    async updateCountry(country) {
      this.savingCountry = true;
      let obj = {
        id: this.shipment.id,
        originCountryId: country.id,
      };
      this.shipment.originCountry = country;
      await this.$API.updateShipment(obj);
      this.savingCountry = false;
      this.editCountryModal = false;
      this.$message.success("Successfully updated country of origin");
    },
    async viewProfile() {
      if (!this.profile) {
        this.loadingProfile = true;
        this.profile = await this.$API.getConsigneeProfile(
          this.shipment.consigneeProfileId
        );
        this.loadingProfile = false;
      }
      this.shipmentProfileModal = true;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: scroll;
}
</style>